import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import cx from 'classnames';
import styles from './Image.module.scss';

const Image = ({ className = '', isLazy = false, ...restProps }) => {
  const imgProps = {
    className: cx(className, styles.img),
    ...restProps,
  };
  if (isLazy) {
    return <LazyLoadImage {...imgProps} />;
  }
  return <img {...imgProps} />;
};

export default Image;
