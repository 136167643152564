import React, { FC, ReactChild, ReactChildren } from 'react';
import Helmet from 'react-helmet';
import { Provider } from 'components/helpers';
import ogImageAsset from 'assets/images/cindersoft-og.jpg';
import { Container, Grid, Cell } from 'components/Grid';

import gridStyles from 'scss/grid.module.scss';

import 'scss/main.scss';

interface OgImageProps {
  src: string;
  height?: string;
  width?: string;
}

interface BaseTemplateProps {
  metaTitle: string;
  metaDescription: string;
  ogUrl?: string;
  ogType?: string;
  ogTitle?: string;
  ogImage?: OgImageProps;
  ogDescription?: string;
  publishedTime?: string;
  author?: string;
  tags?: string[];
  noIndex?: boolean;
  children: ReactChild | ReactChild[];
}

const BaseTemplate = ({
  metaTitle,
  metaDescription,
  ogUrl = undefined,
  ogType = undefined,
  ogTitle = undefined,
  ogDescription = undefined,
  ogImage = {
    src: `${process.env.GATSBY_PUBLIC_URL}${ogImageAsset}`,
    height: '700',
    width: '962',
  },
  publishedTime = undefined,
  author = undefined,
  tags = undefined,
  noIndex = false,
  children,
}: BaseTemplateProps) => {
  return (
    <Provider>
      <Helmet htmlAttributes={{ lang: 'en' }}>
        <title>{metaTitle}</title>
        {true && <meta name='robots' content='noindex,nofollow' />}
        <meta name='description' content={metaDescription} />
        <meta
          property='og:description'
          content={ogDescription || metaDescription}
        />
        <meta
          property='twitter:description'
          content={ogDescription || metaDescription}
        />
        {ogUrl && <meta property='og:url' content={ogUrl} />}
        {ogType && <meta property='og:type' content={ogType} />}
        {ogTitle && <meta property='og:title' content={ogTitle} />}
        <meta property='og:image' content={ogImage.src} />
        {ogImage.height && (
          <meta property='og:image:height' content={ogImage.height} />
        )}
        {ogImage.width && (
          <meta property='og:image:width' content={ogImage.width} />
        )}
        {publishedTime && (
          <meta property='article:published_time' content={publishedTime} />
        )}
        {author && <meta property='article:author' content={author} />}
        {tags &&
          [...tags, 'Cindersoft']?.map((tag) => {
            return <meta key={tag} property='article:tag' content={tag} />;
          })}
        <link
          rel='apple-touch-icon'
          sizes='180x180'
          href='/apple-touch-icon.png'
        />
        <link
          rel='icon'
          type='image/png'
          sizes='32x32'
          href='/favicon-32x32.png'
        />
        <link
          rel='icon'
          type='image/png'
          sizes='16x16'
          href='/favicon-16x16.png'
        />
        <link rel='manifest' href='/site.webmanifest' />
        <link rel='mask-icon' href='/safari-pinned-tab.svg' color='#5bbad5' />
        <meta name='msapplication-TileColor' content='#da532c' />
        <meta name='theme-color' content='#ffffff' />
      </Helmet>
      <main>{children}</main>
      <footer>
        <Container>
          <Grid margin={'-15px'} columns={12}>
            <Cell padding={15} width={{ xs: 12, md: 6 }} push={{ md: 3 }}>
              <div className={gridStyles.textCenter}>
                Copyright © {new Date().getFullYear()} Cindersoft, LLC. All
                rights reserved.
              </div>
            </Cell>
          </Grid>
        </Container>
      </footer>
    </Provider>
  );
};

export default BaseTemplate;
