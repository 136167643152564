import React from 'react';
import { Container } from 'components/Grid';

import { Section } from '../Layout';

const SectionContainer = ({ name, children }) => (
  <Section name={name}>
    <Container>{children}</Container>
  </Section>
);

export default SectionContainer;
