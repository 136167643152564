import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { Waypoint } from 'react-waypoint';
import styles from 'scss/transition.module.scss';

export const TRANSITIONS = {
  REVEAL: 'reveal',
  GROW: 'grow',
  SLIDE_LEFT: 'slideLeft',
  SLIDE_RIGHT: 'slideRight',
};

const TransitionOnEnter = ({ children, transition }) => {
  const [isEntered, setIsIntered] = useState(false);
  const [isPageInteracted, setIsPageInteracted] = useState(false);

  const handleScroll = () => {
    window.removeEventListener('scroll', handleScroll, true);
    setIsPageInteracted(true);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, true);

    return () => {
      window.removeEventListener('scroll', handleScroll, true);
    };
  }, []);

  const handleEnter = () => {
    setIsIntered(true);
  };

  const shouldTransition = isEntered || !isPageInteracted;

  return (
    <Waypoint onEnter={handleEnter}>
      <div
        className={cx(styles.transition, {
          [styles[transition]]: shouldTransition,
        })}
      >
        {children}
      </div>
    </Waypoint>
  );
};

export default TransitionOnEnter;
