import React from 'react';
import { BaseTemplate } from './';
import { Nav } from 'components/navigation';

import 'scss/main.scss';

const DefaultTemplate = ({
  pageTitle,
  metaDescription,
  ogUrl = undefined,
  ogTitle = undefined,
  ogImage = undefined,
  ogType = undefined,
  publishedTime = undefined,
  author = undefined,
  tags = undefined,
  noIndex = false,
  children,
}) => {
  // const [isActive, setIsActive] = useState(false);

  // const showNavbar = (shouldShowNavBar) => {
  //   setIsActive(shouldShowNavBar);
  // };

  const metaTitle = `${pageTitle} | Cindersoft`;

  return (
    <BaseTemplate
      metaTitle={metaTitle}
      metaDescription={metaDescription}
      ogUrl={ogUrl}
      ogType={ogType}
      ogTitle={ogTitle || metaTitle}
      ogImage={ogImage}
      publishedTime={publishedTime}
      author={author}
      tags={tags}
      noIndex={noIndex}
    >
      <Nav />
      {children}
    </BaseTemplate>
  );
};

export default DefaultTemplate;
