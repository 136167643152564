import React, { useState } from 'react';
import styles from './ContactButton.module.scss';
import { Button } from 'components/Buttons';

const mailToLink = `mailto:${process.env.GATSBY_CONTACT_EMAIL}`;

const ContactButton = ({ children }) => {
  const [shouldShowEmail, setShouldShowEmail] = useState(false);
  const showEmail = () => {
    setShouldShowEmail(true);
  };
  const onTouchStart = () => {
    location.href = mailToLink;
    showEmail();
  };

  const href = shouldShowEmail ? mailToLink : '#';

  return (
    <Button
      primary
      classes={styles.contactButton}
      href={href}
      {...(!shouldShowEmail
        ? {
            onTouchStart,
            onClick: showEmail,
            onFocus: showEmail,
            onMouseEnter: showEmail,
          }
        : {})}
    >
      {children}
    </Button>
  );
};

export default ContactButton;
