import React, { FC } from 'react';
import cx from 'classnames';

import styles from './Heading.module.scss';

interface Props {
  children: React.ReactNode;
  classes?: string;
  styleLevel?: number;
  tagLevel?: number;
  strong?: boolean;
  stronger?: boolean;
}

const Heading: FC<Props> = ({
  children = null,
  classes = '',
  styleLevel = null,
  tagLevel = 1,
  strong = false,
  stronger = false,
  ...restProps
}) => {
  const HeadingTag = `h${tagLevel}`;
  const headingLevelStyle = `h${styleLevel || tagLevel}`;

  return (
    <HeadingTag
      className={cx(classes, styles[headingLevelStyle], {
        [styles.strong]: strong,
        [styles.stronger]: stronger,
      })}
      {...restProps}
    >
      {children}
    </HeadingTag>
  );
};

export default Heading;
