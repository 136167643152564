import React, { useEffect } from 'react';
import { throttle } from 'lodash';
import cx from 'classnames';
import gridStyles from 'scss/grid.module.scss';
import styles from './Nav.module.scss';
import { Grid, Cell } from 'components/Grid';
import Image from 'components/Layout/Image';
import { Link } from 'gatsby';
import { ContactButton } from 'components/Buttons';

import nLogo from 'assets/images/cindersoft-logo-icon.png';

const Nav = ({
  showNavbar = (x) => undefined,
  isDark = false,
  isActive = false,
  isHidden = false,
  isSticky = false,
  revealAfterSplash = false,
}) => {
  const handleScroll = throttle(() => {
    if (!revealAfterSplash) {
      showNavbar(window.scrollY >= 300);
      return;
    }

    const mobileOffset = window.innerHeight > window.innerWidth ? 90 : 0;
    showNavbar(window.scrollY >= window.innerHeight + mobileOffset);
  }, 33);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  });

  return (
    <nav
      className={cx(styles.nav, gridStyles.hidePrint, {
        [styles.active]: isActive,
        [styles.dark]: isDark,
        [styles.hidden]: isHidden,
        [styles.sticky]: isSticky,
      })}
    >
      <div className={styles.body}>
        <Grid columns={12}>
          <Cell width={12}>
            <div className={styles.navLinks}>
              <div className={cx(styles.navItem, styles.navLogoLink)}>
                <Link to='/'>
                  <Image
                    src={nLogo}
                    alt='Cindersoft Logo'
                    width='40'
                    height='40'
                  />
                </Link>
              </div>
              {/* <Link className={styles.navLink} to='/blog/'>
                Blog
              </Link>
              <Link className={styles.navLink} to='/resume/'>
                Resume
              </Link> */}
              <div
                className={cx(
                  styles.navItem,
                  styles.navItemEnd,
                  styles.navItemEndHiddenXs,
                )}
              >
                <ContactButton>Contact</ContactButton>
              </div>
            </div>
          </Cell>
        </Grid>
      </div>
    </nav>
  );
};

export default Nav;
